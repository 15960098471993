// If you want to override variables do it here
//@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui-pro/scss/coreui.scss";

// If you want to add something do it here
@import "custom";

.dateRangeInput {
    width: 150px;
    float: left;
    margin: 15px;
}
.navBarObjects {
    margin-left: 10px;
}

.loader {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #336bdc;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.outlineOk {
    box-shadow: 0 0 20px #29be2f;
}
.outlineFail {
    box-shadow: 0 0 20px #be0626;
}

.react-select__single-value {
    z-index: unset !important;
}
