.DatePickerV2_container {
    width: 100%;
    position: relative;
}

.DatePickerV2_extendedModul {
    background-color: #121212;
    width: 500px;
    // height: 500px;
    position: absolute;
    left: 0;
    top: 50px;
    z-index: 2000000;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 630px;

    &.lightMode {
        background-color: #fff;
    }

    @media (max-width: 768px) {
        width: 100%;
    }
}

.DatePickerV2_pickerToolbar {
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.DatePickerV2_readyToUseDateButtons {
    padding: 5px 10px;
    background-color: rgba(255, 255, 255, 0.2);
    display: inline-block;
    width: max-content;
    border-radius: 10px;
    cursor: pointer;
    transition: all 500ms ease-in-out;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    user-select: none;
    margin-right: 10px;
    margin-top: 10px;

    &:hover {
        background-color: rgba(255, 255, 255, 0.5);
    }

    &.lightMode {
        background-color: rgba(0, 0, 0, 0.2);
        &:hover {
            background-color: rgba(0, 0, 0, 0.5);
        }
    }
}

.DatePickerV2_buttonZone {
    padding: 10px 20px;
}